.react-calendar__tile--now,
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: whitesmoke;
}

.react-calendar__tile--hasActive {
  background-color: transparent;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus,
.react-calendar__tile--active {
  background-color: transparent;
  color: grey;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background-color: whitesmoke;
}
