/* Quita la 'X' por defecto de HTML5 en Internet Explorer para los input de tipo 'search' */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* Quita la 'X' por defecto de HTML5 en Google Chrome para los input de tipo 'search' */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
